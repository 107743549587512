<template>
  <page>
    <slot v-if="isAuthenticated"></slot>
    <full-page-message v-else>
      <loading-spinner />
    </full-page-message>
  </page>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

import Page from '@/components/templates/Page'
import LoadingSpinner from '@/components/atoms/LoadingSpinner'
import FullPageMessage from '@/components/templates/FullPageMessage'

export default defineComponent({
  name: 'AuthenticatedPage',
  components: {
    Page,
    LoadingSpinner,
    FullPageMessage,
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
})
</script>

<template>
  <div class="flex items-center justify-center space-x-2 animate-pulse">
    <div class="w-3 h-3 rounded-full" :class="className" />
    <div class="w-3 h-3 rounded-full" :class="className" />
    <div class="w-3 h-3 rounded-full" :class="className" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LoadingSpinner',
  props: {
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    className() {
      return {
        'bg-primary': this.primary,
        'bg-secondary-500': this.secondary,
        'bg-bg-secondary-100': !this.primary && !this.secondary,
      }
    },
  },
})
</script>

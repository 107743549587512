<template>
  <div
    class="
      text-secondary-100
      bg-primary
      h-full
      flex flex-col
      items-center
      justify-center
    "
  >
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

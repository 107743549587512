<template>
  <authenticated-page />
</template>

<script>
import AuthenticatedPage from '@/components/templates/AuthenticatedPage.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Logged',
  components: { AuthenticatedPage },
  computed: {
    tokenPayload() {
      return this.$store?.getters?.idTokenPayload
    },
  },
  data: () => ({
    timeoutID: 0,
  }),
  watch: {
    tokenPayload(newValue) {
      if (newValue != null) this.$router.push('/')
    },
  },
  mounted() {
    this.timeoutID = setTimeout(() => this.$router.push('/'), 5000)
    console.log(`mounted | ${this.timeoutID}`)
  },
  beforeUnmount() {
    console.log(`beforeUnmount | ${this.timeoutID}`)
    if (this.timeoutID) clearTimeout(this.timeoutID)
  },
})
</script>

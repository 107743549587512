<template>
  <ion-page>
    <ion-content :fullscreen="true"><slot></slot></ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Page',
  components: {
    IonContent,
    IonPage,
  },
})
</script>
